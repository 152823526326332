<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card>
          <v-card-text>
            <v-container>
              <div style="color:red">{{error}}</div>
              <div>Welcome to OMAR please enter in a password to continue:</div>
              <v-form ref="form">
                <v-col cols="12">
                  <v-text-field v-model="email" label="Email" disabled></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field :type="'password'" v-model="password" label="Password" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :type="'password'"
                    v-model="confirmPassword"
                    label="Password"
                    required
                    @keyup.enter="signinUserPass"
                  ></v-text-field>
                </v-col>
                <v-btn color="primary" @click="signIn">Change password.</v-btn>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
var db = firebase.firestore();
export default {
  data: () => ({
    email: "",
    password: "",
    confirmPassword: "",
    error: ""
  }),
  mounted() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      this.email = atob(this.$route.query.e);
    }
  },

  methods: {
    signIn() {
      // Confirm the link is a sign-in with email link.
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.

        var me = this;
        if (this.password != this.confirmPassword) {
          this.$store.dispatch("setToast", {
            message: "Password and confirm password must match",
            color: "red darken-4"
          });
        }
        if (this.password == this.confirmPassword) {
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(function(result) {
              console.log("provider login success : " + result.user.email);

              //get the user ref
              db.collection("users")
                .where("email", "==", me.email.toLowerCase())
                .get()
                .then(usersSnapshot => {
                  let data = result.user.toJSON();
                  data.status = "Active";
                  data.isEnabled = true;
                  data.organisationId = me.$route.query.orgID;

                  if (usersSnapshot.size == 1) {
                    let userdata = usersSnapshot.docs[0].data();
                    let id = usersSnapshot.docs[0].id;
                    data.id = id;
                    data.createdOn =userdata.createdOn;
                    data.modifiedOn=userdata.modifiedOn;
                    data.roles = userdata.roles;
                    data.firstSignInDate = new Date();
                    me.$store.commit("setUser", data);
                    db.collection("users")
                      .doc(id)
                      .set(data)
                      .then(payload => {
                        me.setUser(me, result, data);

                        console.log(payload);
                      })
                      .catch(error => {
                        console.log(error);
                        me.error = error.message;
                        me.status += "failure: " + JSON.stringify(error);
                        this.$store.dispatch("setToast", {
                          message: "Sign-in error",
                          color: "red darken-4"
                        });
                      });
                  }
                });
            })
            .catch(error => {
              this.$store.dispatch("setToast", {
                message: error,
                color: "red darken-4"
              });
            });
        }
        this.status += "<br />" + this.email;
      }
    },
    setUser(me, result, userData) {
      console.log(result);

      db.collection("organisations")
        .doc(userData.organisationId)
        .get()
        .then(organisationDoc => {
          const org = organisationDoc.data();
          org.id = organisationDoc.id;
          me.$store.commit("setCurrentOrganisation", org);
          me.$store.commit("setUser", userData);
          me.$router.push("/");
        })
        .catch(function(error) {
          console.log(error);
          me.$store.commit("setUser", null);
          me.$store.dispatch("setToast", {
            message: "Sign-in error",
            color: "red darken-4"
          });
        });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    }
  }
};
</script>
